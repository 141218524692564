import { Controller } from "stimulus"
import $ from 'jquery';

require("select2/dist/css/select2")
require("select2-bootstrap-theme/dist/select2-bootstrap")
import reservationContract from "select2"
// require('block-ui/jquery.blockUI')
export default class extends Controller {
    connect() {
        $(".js-example-responsive").select2({
            width: '100' // need to override the changed default
        });

        $('.mc2ec_select').select2({
            width: '100%',
            multiple: true,
            language: "es",
            theme: "default",
            dropdownCssClass: 'input-border-color mt-1 border',
            selectionCssClass: 'shadow-none form-control input-border-color rounded',
        });
    }
}
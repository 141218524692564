import {Controller} from "stimulus";
import $ from "jquery";

export default class extends Controller {
    connect() {
        $('#created_at_min').datepicker('option', 'maxDate', new Date());
        $('#created_at_max').datepicker('option', 'maxDate', new Date());
    }

    updateFromDate(e){
        let from_date_min = $('#from_date_min').datepicker('getDate');
        $('#from_date_max').datepicker('option', 'minDate', from_date_min);
    }

    updateToDate(e){
        let to_date_min = $('#to_date_min').datepicker('getDate');
        $('#to_date_max').datepicker('option', 'minDate', to_date_min);
    }

    updateCreatedAt(e){
        let created_at_min = $('#created_at_min').datepicker('getDate');
        $('#created_at_max').datepicker('option', 'minDate', created_at_min);
    }
}

import consumer from "./consumer"
import $ from "jquery";
import {toastr_message} from "../controllers/globalFunctions_controller";

require('block-ui/jquery.blockUI');
// variable para guardar la subscription y evitar subscripciones repetidas
export let NotificationChannelSubscription
// console.log(NotificationChannelSubscription);

document.addEventListener('turbo:load', () => {
    $(function () {
        const user_element = document.getElementById('user_notification');
        if (user_element && !NotificationChannelSubscription) {
            const user_id = Number(user_element.getAttribute('data-user-id'))
            NotificationChannelSubscription =  consumer.subscriptions.create({channel: "NotificationChannel", user_id: user_id}, {
                connected() {
                    console.log("Conectado al canal NotificationChannel" + user_id);
                },

                disconnected() {
                    // Called when the subscription has been terminated by the server
                },

                received(data) {
                    let off_canvas = $('#off-canvas');
                    let background_progress = $('#background_progress');
                    let progress = $(`#${data.progress.id}`);

                    background_progress.css('display', 'block');

                    // Creando el item progress si no existe
                    if (data.progress.id !== undefined && progress.length === 0) {
                        off_canvas.append(`
                                    <span id="span_${data.progress.id}" class="strong">${data.progress.name}</span>
                                    <div class="progress" id="progress_${data.progress.id}" data-percent="0" style="height: 20px !important;">
                                        <div id="${data.progress.id}" class="progress-bar progress-bar-striped progress-bar-animated " 
                                            role="progressbar" aria-valuemin="0" aria-valuemax="100">
                                        </div>
                                    </div>
                                `)
                    }

                    if (data.progress.status > 0 && progress.length > 0) {
                        progress[0].innerText = `${data.progress.status}%`;
                        progress.css('width', `${data.progress.status}%`);
                        $(`#progress_${data.progress.id}`).attr('data-percent', data.progress.status);
                        $(`#span_${data.progress.id}`).text(data.progress.name);
                    }

                    if (data.progress.status === 100 && data.progress.download !== undefined && data.progress.download !== "") {
                        // Para los reportes en JOBs
                        window.location.href = `${data.progress.download}`;
                        setTimeout(function () {
                            let span = $(`#span_${data.progress.id}`);
                            toastr_message('success', `${span.text()} completado`, 'Reporte');
                            $(`#progress_${data.progress.id}`).remove();
                            span.remove();
                        }, 1000);
                    }

                    //Creando una notificacion de error con parametros ( message ) pasados por el canal
                    if(data.progress.notify !== undefined && data.progress.notify === 'error'){
                        setTimeout(function () {
                            let span = $(`#span_${data.progress.id}`);
                            toastr_message('error', `${data.progress.message}`, `${data.progress.title!==undefined ? data.progress.title: ''}`);
                            background_progress.css('display', 'none');
                            $(`#progress_${data.progress.id}`).remove();
                            span.remove();
                        }, 1000);
                        return;
                    }

                    // Calcular el progreso general
                    let progress_list = $("div[class='progress'][id*='progress_']");
                    let total = 0; let media = 0;
                    $.each(progress_list, function (key, item) {
                        let percent = parseFloat(item.dataset.percent);
                        if ($.isNumeric(percent)) {
                            total += percent;
                        }
                    });

                    if (progress_list.length > 0 && total > 0){
                        media = Math.trunc(total / progress_list.length);
                    }
                    if (media > 0) {
                        background_progress[0].innerText = `${media}%`;
                        background_progress.css('--percent', `${media}%`);
                        if (media === 100) {
                            setTimeout(function () {
                                // Retornar valores iniciales
                                background_progress.css('display', 'none');
                                background_progress.html(`<span class='fas fa-spinner fa-pulse'></span>`);
                            }, 5000);
                        }
                    }
                }
            })
        }
        // console.log(NotificationChannelSubscription);
        // console.log(user_element);
    })
});

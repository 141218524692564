import {Controller} from "stimulus";
import $ from "jquery";
import {ajaxErrorMessages, myToFixed} from "./globalFunctions_controller";
import adjust_controller from "./adjust_controller";

require('block-ui/jquery.blockUI');

export default class extends Controller {
    connect() {
        $('#basic_business_id').select2({
            theme: "bootstrap",
            language: "es"
        });

        $("#basic_business_id").on('select2:select', function () {
            let event = new Event('change', {bubbles: true})
            this.dispatchEvent(event);
        });
        //precargando el formulario con los campos voucher deshabilitados en caso de no ser voucher la renta
        // this.unCheckNoVouchersOperations();
        this.changeInputsBehavior();
        //predefiniendo el valor maximo de los inputs de deuda
        this.setMaxValueToDebtsInputs();
    }

    myToFixed(num, precision) {
        return (+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision);
    }

    selectAllVouchers(e) {
        $('#check_all_vouchers').change(function () {
            let vouchers_items = $('.vouchers_items');
            if ($(this).is(':checked')) {
                vouchers_items.prop('checked', true);
                vouchers_items.prop('disabled', true);
                $('#btn-create_lot').removeClass('disabled');
                $('#btn-preview-rent').removeClass('disabled');
            } else {
                vouchers_items.prop('checked', false);
                vouchers_items.prop('disabled', false);
                $('#btn-create_lot').addClass('disabled');
                $('#btn-preview-rent').addClass('disabled');
            }
        })
    }

    enableBtnCreateLot(e) {
        if ($('.vouchers_items').is(':checked')) {
            $('#btn-create_lot').removeClass('disabled');
            $('#btn-preview-rent').removeClass('disabled');
        } else {
            $('#btn-create_lot').addClass('disabled');
            $('#btn-preview-rent').addClass('disabled');
        }
    }

    selectAllLots(e) {
        $('#check_all_lots').change(function () {
            let lot_items = $('.lot_items');
            if ($(this).is(':checked')) {
                lot_items.prop('checked', true);
                lot_items.prop('disabled', true);
                $('#btn-pre_bill_lot').removeClass('disabled');
            } else {
                lot_items.prop('checked', false);
                lot_items.prop('disabled', false);
                $('#btn-pre_bill_lot').addClass('disabled');
            }
        })
    }

    selectAllRents(e) {
        $('#check_all_rents').change(function () {
            let rent_items = $('.rent_items');
            if ($(this).is(':checked')) {
                rent_items.prop('checked', true);
                rent_items.prop('disabled', true);
                $('#btn-ready_to_bill').removeClass('disabled');
            } else {
                rent_items.prop('checked', false);
                rent_items.prop('disabled', false);
                $('#btn-ready_to_bill').addClass('disabled');
            }
        })
    }

    enableBtnPreBillLot(e) {
        if ($('.lot_items').is(':checked')) {
            $('#btn-pre_bill_lot').removeClass('disabled');
        } else {
            $('#btn-pre_bill_lot').addClass('disabled');
        }
    }

    enableBtnReadyToBill(e) {
        if ($('.rent_items').is(':checked')) {
            $('#btn-ready_to_bill').removeClass('disabled');
        } else {
            $('#btn-ready_to_bill').addClass('disabled');
        }
    }

    //ACTUALIZAR TOTAL DE IMPORTES
    updateTotalImport(e) {
        //renta
        let days = parseInt($('#amounts_days').val());
        if ($('#amounts_days').val() === '' || $('#amounts_days').val() === undefined)
            days = 0.00
        let amounts_rent_tariff = $('#amounts_rent_tariff').val()
        let rent = $('#amounts_rent_import').val();
        if (rent === '' || rent === undefined || rent === null) {
            rent = 0.00
        }
        // else {
        //     // rent = amounts_rent_tariff * days
        //     // $('#amounts_rent_import').val(this.myToFixed(rent, 2))
        // }
        //seguro
        let amounts_liability_release_tariff = $('#amounts_liability_release_tariff').val();
        let insurance = $('#liability_release_import').val();
        if (insurance === '' || insurance === undefined || insurance === null) {
            insurance = 0.00
        }
        // else {
        //     insurance = amounts_liability_release_tariff * days
        //     $('#liability_release_import').val(this.myToFixed(insurance, 2));
        // }
        //suplemento de relevo
        let relief_supplement_tariff = $('#relief_supplement_tariff').val();
        let relief_supplement = $('#relief_supplement_import').val();
        if (relief_supplement === '' || relief_supplement === undefined || relief_supplement === null) {
            relief_supplement = 0.0
        }
        // else {
        //     relief_supplement = relief_supplement_tariff * days
        //     $('#relief_supplement_import').val(this.myToFixed(relief_supplement, 2));
        // }
        //drop off
        let drop_off = $('#amounts_drop_off').val();
        if (drop_off === '' || drop_off === undefined || drop_off === null) {
            drop_off = 0.0
        }
        //combustible
        let fuel_imp = $('#amounts_fuel_import').val();
        if (fuel_imp === '' || fuel_imp === undefined || fuel_imp === null) {
            fuel_imp = 0.0
        }
        //oficina
        let office = $('#amounts_office').val();
        if (office === '' || office === undefined || office === null) {
            office = 0.0
        }
        //choferes
        let additional_driver_tariff = $('#additional_driver_tariff').val();
        let r_additional_driver_collect = $("#r_additional_driver_collect").val(); // cantidad de choferes cobrados en la renta
        let drivers = 0.0;
        if (r_additional_driver_collect > 0) {
            drivers = additional_driver_tariff * days * r_additional_driver_collect
            for (let i = 1; i <= r_additional_driver_collect; i++) {
                $("#drivers_" + i + "_import").val(this.myToFixed(days * additional_driver_tariff, 2));
            }
        }
        // Otros
        let others = $("#amounts_other_payments").val()
        if (others === '' || others === undefined || others === null) {
            others = 0.0
        }
        let total_im = parseFloat(office) + parseFloat(fuel_imp) + parseFloat(insurance) + parseFloat(rent) + parseFloat(drivers) + parseFloat(others) + parseFloat(drop_off) + parseFloat(relief_supplement)
        $("#total_rent_import").text(this.myToFixed(total_im, 2));
        this.updatePaymentForm(e);
    }

    updateRentImport(e) {
        let tariff = parseFloat($("#amounts_rent_tariff").val());
        let days = parseInt($("#amounts_days").val());
        let debt_rent_import = $('input[name="amounts[debt_rent_import]"]');
        let rent_import = parseFloat($('#amounts_rent_import').val());
        if ($('#amounts_days').val() === '' || $('#amounts_days').val() === undefined)
            days = 0.00
        if ($('#amounts_rent_tariff').val() === '' || $('#amounts_rent_tariff').val() === undefined)
            tariff = 0.00
        // $('#amounts_rent_import').val(this.myToFixed(tariff * days, 2));
        // this.updateTotalImport(e);
        if ($("#voucher_rent").is(":checked")) {
            debt_rent_import.val(myToFixed(0.00, 2));
            $('#amounts_rent_import').val(this.myToFixed(tariff * days, 2));
        } else {
            debt_rent_import.val(myToFixed(tariff * days - rent_import, 2));
        }
        this.updateTotalImport(e);
    }

    liabilityReleaseRentImport(e) {
        let tariff = parseFloat($("#amounts_liability_release_tariff").val());
        let days = parseInt($("#amounts_days").val());
        let debt_liability_release_import = $('input[name="amounts[debt_liability_release_import]"]');
        let liability_release_import = parseFloat($('#liability_release_import').val());
        if ($('#amounts_days').val() === '' || $('#amounts_days').val() === undefined)
            days = 0.00
        if ($('#amounts_liability_release_tariff').val() === '' || $('#amounts_liability_release_tariff').val() === undefined)
            tariff = 0.00
        // $('#liability_release_import').val(this.myToFixed(tariff * days, 2));
        // this.updateTotalImport(e);
        if ($("#voucher_liability_release").is(":checked")) {
            debt_liability_release_import.val(myToFixed(0.00, 2));
            $('#liability_release_import').val(this.myToFixed(tariff * days, 2));
        } else {
            debt_liability_release_import.val(myToFixed(tariff * days - liability_release_import, 2));
        }
        // debt_liability_release_import.val(myToFixed(tariff * days - liability_release_import, 2));
        this.updateTotalImport(e);
    }

    updateReliefSupplementImport(e) {
        let tariff = parseFloat($("#relief_supplement_tariff").val());
        let days = parseInt($("#amounts_days").val());
        let debt_relief_supplement_import = $('input[name="amounts[debt_relief_supplement_import]"]');
        let relief_supplement_import = parseFloat($('#relief_supplement_import').val());
        if ($('#amounts_days').val() === '' || $('#amounts_days').val() === undefined)
            days = 0.00
        if ($('#relief_supplement_tariff').val() === '' || $('#relief_supplement_tariff').val() === undefined)
            tariff = 0.00
        // $('#relief_supplement_import').val(this.myToFixed(tariff * days, 2));
        // this.updateTotalImport(e);
        if ($("#voucher_relief_supplement").is(":checked")) {
            debt_relief_supplement_import.val(myToFixed(0.00, 2));
            $('#relief_supplement_import').val(this.myToFixed(tariff * days, 2));
        } else {
            debt_relief_supplement_import.val(myToFixed(tariff * days - relief_supplement_import, 2));
        }
        // debt_relief_supplement_import.val(myToFixed(tariff * days - relief_supplement_import, 2));
        this.updateTotalImport(e);
    }

    updateVoucherPayment(e) {
        // variables checked de conductores adicionales
        let r_additional_driver_collect = parseInt($("#r_additional_driver_collect").val());
        let drivers_import = 0
        for (let i = 1; i <= r_additional_driver_collect; i++) {
            if ($("#voucher_drivers_" + i).is(":checked")) {
                drivers_import += parseFloat($("#drivers_" + i + "_import").val());
            }
        }
        let total_voucher = drivers_import
        if ($("#voucher_rent").is(":checked")) {
            total_voucher += parseFloat($("#amounts_rent_import").val());
        }
        if ($("#voucher_liability_release").is(":checked")) {
            total_voucher += parseFloat($("#liability_release_import").val());
        }
        if ($("#voucher_relief_supplement").is(":checked")) {
            total_voucher += parseFloat($("#relief_supplement_import").val());
        }
        if ($("#voucher_fuel").is(":checked")) {
            total_voucher += parseFloat($("#amounts_fuel_import").val());
        }
        if ($("#voucher_office").is(":checked")) {
            total_voucher += parseFloat($("#amounts_office").val());
        }
        if ($("#voucher_drop_off").is(":checked")) {
            total_voucher += parseFloat($("#amounts_drop_off").val());
        }
        if ($("#voucher_other_payments").is(":checked")) {
            total_voucher += parseFloat($("#amounts_other_payments").val());
        }
        $("#voucher").val(this.myToFixed(total_voucher, 2));
    }

    /**
     * Busca la direfencia entre total de la renta y lo ya desglosado en las diferentes formas de pago
     * sin incluir tarjeta al momento.
     * Actualiza la forma de pago con la diferencia si esta es positiva.
     * */
    updateMomentCardPayment(e) {
        let cash = $('#cash').val() !== '' ? parseFloat($('#cash').val()) : 0.0;
        let card = $('#card').val() !== '' ? parseFloat($('#card').val()) : 0.0;
        let voucher = $('#voucher').val() !== '' ? parseFloat($('#voucher').val()) : 0.0;
        let cheque = $('#cheque').val() !== '' ? parseFloat($('#cheque').val()) : 0.0;
        let other = $('#other').val() !== '' ? parseFloat($('#other').val()) : 0.0;
        let payment = cash + card + voucher + cheque + other
        let total_rent_import = parseFloat($('#total_rent_import').text());

        if (total_rent_import - payment > 0)
            $('#moment_card').val(this.myToFixed(total_rent_import - payment, 2));
        else
            $('#moment_card').val(this.myToFixed(0, 2));
    }

    updatePaymentForm(e) {
        this.updateVoucherPayment(e);
        this.updateMomentCardPayment(e);
        this.checkButton(e)
    }

    totalPaymentForm() {
        let cash = $('#cash').val() !== '' ? parseFloat($('#cash').val()) : 0.0;
        let card = $('#card').val() !== '' ? parseFloat($('#card').val()) : 0.0;
        let moment_card = $('#moment_card').val() !== '' ? parseFloat($('#moment_card').val()) : 0.0;
        let voucher = $('#voucher').val() !== '' ? parseFloat($('#voucher').val()) : 0.0;
        let cheque = $('#cheque').val() !== '' ? parseFloat($('#cheque').val()) : 0.0;
        let other = $('#other').val() !== '' ? parseFloat($('#other').val()) : 0.0;
        return cash + card + moment_card + voucher + cheque + other
    }

    /**
     * Verificar la distribucion del importe y que se haya realizado algun cambio,
     * para activar o desactivar el boton de ajuste del formulario.
     * */
    checkButton(e) {
        let total = this.totalPaymentForm();
        let to_distribute = parseFloat($('#total_rent_import').text());
        console.log((to_distribute - total) === 0 && this.has_changes());
        console.log("a distribuir: " + to_distribute + " total: " + total);
        if ((to_distribute - total) === 0 && this.has_changes()) {
            $('#btn-adjust').removeClass('disabled');
            console.log("activado: checkButton");
        } else {
            $('#btn-adjust').addClass('disabled');
            console.log("desactivado: checkButton")
        }
    }

    has_changes() {
        let change = false;
        let input_form = $('.input-change');
        let check_form = $('.check-change');
        let debts = $('.debt');
        let context = this;
        change = this.isChangeItAnyPaymentImport();
        // Buscando cambios en los input de entrada
        input_form.each(function (idx, el) {
            let current = parseFloat($(el).val());
            let initial = parseFloat($(el).data('initial'));
            // console.log("Nombre: " + el.name+" Valor actual: " +current+ " Valor inicial: "+ initial );
            if (current !== initial) {
                change = true;
                return false; //break
            }
        });
        //Buscando cambios en los checks
        check_form.each(function (idx, el) {
            let current = $(el).is(":checked");
            let initial = $(el).data('initial');
            if (current !== initial) {
                change = true;
                return false; //break
            }
        });

        //buscando cambios en las deudas
        debts.each(function (idx, el) {
            if(context.checkChangesInDebts(el)){
                change = true;
                return false;
            }
        });
        // change = this.isChangeItAnyPaymentImport();
        return change;
    }

    // ------------------------------------------------- ACTUALIZAR DIFERENCIAS ----------------------------------------
    updateRentDiff() {
        let rent_debt = parseFloat($('#debt_rent_import').val());
        let diff_rent = parseFloat($('#rv_payment_rent').text()) - (parseFloat($('#amounts_rent_import').val()) + rent_debt);
        $('#diff_rent').text(this.myToFixed(diff_rent, 2));
        if (diff_rent !== 0)
            $('#tr_rent').addClass('table-danger');
        else
            $('#tr_rent').removeClass('table-danger');
    }

    updateLiabilityReleaseDiff() {
        let liability_release_debt = parseFloat($('#debt_liability_release_import').val());
        let diff_liability_release = parseFloat($('#rv_payment_liability_release').text()) - (parseFloat($('#liability_release_import').val()) + liability_release_debt);
        $('#diff_liability_release').text(this.myToFixed(diff_liability_release, 2));
        if (diff_liability_release !== 0)
            $('#tr_liability_release').addClass('table-danger');
        else
            $('#tr_liability_release').removeClass('table-danger');
    }

    updateReliefSupplementDiff() {
        let relief_supplement_debt = parseFloat($('#debt_relief_supplement_import').val());
        let diff_relief_supplement = parseFloat($('#rv_payment_relief_supplement').text()) - (parseFloat($('#relief_supplement_import').val()) + relief_supplement_debt);
        $('#diff_relief_supplement').text(this.myToFixed(diff_relief_supplement, 2));
        if (diff_relief_supplement !== 0)
            $('#tr_relief_supplement').addClass('table-danger');
        else
            $('#tr_relief_supplement').removeClass('table-danger');
    }

    updateAdditionalDriverDiff() {
        let days = parseInt($('#amounts_days').val());
        if ($('#amounts_days').val() === '' || $('#amounts_days').val() === undefined)
            days = 0.00
        let additional_driver_tariff = $('#additional_driver_tariff').val();
        let r_additional_driver_collect = $("#r_additional_driver_collect").val(); // cantidad de choferes cobrados en la renta
        if (r_additional_driver_collect > 0) {
            for (let i = 1; i <= r_additional_driver_collect; i++) {
                let r_driver_import = days * additional_driver_tariff;
                let debt_additional_driver = parseFloat($("#debt_additional_driver_"+ i +"_import").val());
                let diff_additional_driver = parseFloat($("#rv_payment_additional_driver_" + i).text()) - (r_driver_import + debt_additional_driver);
                $("#diff_additional_driver_" + i).text(this.myToFixed(diff_additional_driver, 2));
                $("#drivers_" + i + "_import").val(this.myToFixed(r_driver_import, 2));
                if (diff_additional_driver !== 0)
                    $("#tr_diff_additional_driver_" + i).addClass('table-danger');
                else
                    $("#tr_diff_additional_driver_" + i).removeClass('table-danger');
            }
        }
    }

    updateFuelDiff() {
        let diff_fuel = 0.00
        let debt_fuel = 0.00;
        if ($('#amounts_fuel_import').val() != "" && $('#amounts_fuel_import').val() != undefined) {
            debt_fuel = parseFloat($('#debt_fuel_import').val());
            diff_fuel = parseFloat($('#rv_payment_initial_fuel').text()) - (parseFloat($('#amounts_fuel_import').val()) + debt_fuel);
        }
        $('#diff_fuel').text(this.myToFixed(diff_fuel, 2));
        if (diff_fuel !== 0)
            $('#tr_fuel').addClass('table-danger');
        else
            $('#tr_fuel').removeClass('table-danger');
    }

    updateOfficeDiff() {
        let diff_office = 0.00;
        let debt_office = 0.00;
        if ($('#amounts_office').val() != "" && $('#amounts_office').val() != undefined) {
            debt_office = parseFloat($('#debt_office_import').val());
            diff_office = parseFloat($('#rv_payment_office_supplement').text()) - (parseFloat($('#amounts_office').val()) + debt_office);
        }
        $('#diff_office').text(this.myToFixed(diff_office, 2));
        if (diff_office !== 0)
            $('#tr_office').addClass('table-danger');
        else
            $('#tr_office').removeClass('table-danger');
    }

    updateDropOffDiff() {
        let diff_drop_off = 0.00;
        let debt_drop_off = 0.00;
        if ($('#amounts_drop_off').val() != "" && $('#amounts_drop_off').val() != undefined) {
            debt_drop_off = parseFloat($("#debt_drop_off_import").val());
            diff_drop_off = parseFloat($('#rv_payment_drop_off').text()) - (parseFloat($('#amounts_drop_off').val()) + debt_drop_off);
        }
        $('#diff_drop_off').text(this.myToFixed(diff_drop_off, 2));
        if (diff_drop_off !== 0)
            $('#tr_drop_off').addClass('table-danger');
        else
            $('#tr_drop_off').removeClass('table-danger');
    }

    updatePaymentOtherDiff() {
        let diff_payment_other = 0.00;
        let debt_other = 0.00;
        if ($('#amounts_other_payments').val() != "" && $('#amounts_other_payments').val() != undefined) {
            debt_other = parseFloat($("#debt_other_import").val());
            diff_payment_other = parseFloat($('#rv_payment_other').text()) - (parseFloat($('#amounts_other_payments').val()) + debt_other);
        }
        $('#diff_payment_other').text(this.myToFixed(diff_payment_other, 2));
        if (diff_payment_other !== 0)
            $('#tr_payment_other').addClass('table-danger');
        else
            $('#tr_payment_other').removeClass('table-danger');
    }

    updateTotalImportDiff() {
        let diff_total_import = parseFloat($('#total_reservation_import').text()) - (parseFloat($('#total_rent_import').text()) + this.resolveDebtTotalImport());
        $('#diff_total_import').text(this.myToFixed(diff_total_import, 2));
    }

    update_all_differences(e) {
        this.updateRentDiff(); // Renta
        this.updateLiabilityReleaseDiff(); // Relevo
        this.updateReliefSupplementDiff(); // Suplemento
        this.updateAdditionalDriverDiff(); // Conductor Adicional
        this.updateFuelDiff(); // Combustible
        this.updateOfficeDiff(); // Cargo por oficina
        this.updateDropOffDiff(); // Drop Off
        this.updatePaymentOtherDiff(); // Otros
        // TOTAL
        this.updateTotalImportDiff();
    }

    // -----------------------------------------------------------------------------------------------------------------

    /**
     * Encontrar los lotes de renta de una Agencia
     * */
    findLotsByBasicBusiness(e) {
        let url = this.data.get("lots_url");
        $.blockUI({message: false});
        jQuery.ajax({
            async: true,
            evalScripts: true,
            type: "POST",
            contentType: "application/x-www-form-urlencoded",
            url: url,
            data: {basic_business_id: e.target.value},
            success: function (res) {
                $.unblockUI();
                $("#rent_lot_id").empty();
                $("#rent_lot_id").append('<option selected="selected" value=""> Lotes prefacturados, no listos </option>')
                if (res !== undefined) {
                    $.each(res.lots, function (index, lot) {
                        $("#rent_lot_id").append(
                            $("<option>")
                                .text(lot[0])
                                .attr("value", lot[1])
                        );
                    });
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                $.unblockUI();
                ajaxErrorMessages(jqXHR, textStatus, errorThrown);
            },
        });
    }

    changeInputsBehavior(e) {
        let context = this;
        let days = parseInt($("#amounts_days").val());
        //inputs de tarifas
        let amounts_rent_tariff = parseFloat($('#amounts_rent_tariff').val());
        let amounts_liability_release_tariff = parseFloat($('#amounts_liability_release_tariff').val());
        let relief_supplement_tariff = parseFloat($('#relief_supplement_tariff').val());
        //inputs de importes
        let rent_import_input = $('#amounts_rent_import');
        let liability_release_import_input = $('#liability_release_import');
        let relief_supplement_import_input = $('#relief_supplement_import');
        let drivers_input = $('.drivers');
        let fuel_import_input = $('#amounts_fuel_import');
        let office_input = $('#amounts_office');
        let drop_off_input = $('#amounts_drop_off');
        let other_payments_input = $('#amounts_other_payments');
        //checkbox de vouchers
        let other_payments_voucher = $('input[name="voucher[other_payments]"]');
        let drop_off_voucher = $('input[name="voucher[drop_off]"]');
        let office_voucher = $('input[name="voucher[office]"]');
        let fuel_voucher = $('input[name="voucher[fuel]"]');
        let drivers_voucher = $('.voucher_drivers');
        let relief_supplement_voucher = $('input[name="voucher[relief_supplement]"]');
        let liability_release_voucher = $('input[name="voucher[liability_release]"]');
        let rent_voucher = $('input[name="voucher[rent]"]');
        //inputs de deudas
        let debt_rent_import = $('input[name="amounts[debt_rent_import]"]');
        let debt_liability_release_import = $('input[name="amounts[debt_liability_release_import]"]');
        let debt_relief_supplement_import = $('input[name="amounts[debt_relief_supplement_import]"]');
        let debt_additional_driver_import = $('input[name="amounts[debt_additional_driver_import]"]');
        let debt_fuel_import = $('input[name="amounts[debt_fuel_import]"]');
        let debt_office_import = $('input[name="amounts[debt_office_import]"]');
        let debt_drop_off_import = $('input[name="amounts[debt_drop_off_import]"]');
        let debt_other_import = $('input[name="amounts[debt_other_import]"]');
        //seccion choferes adicionales
        let additional_driver_tariff = $('#additional_driver_tariff').val();
        let r_additional_driver_collect = $("#r_additional_driver_collect").val(); // cantidad de choferes cobrados en la renta
        let count_drivers_import = 0
        // if(r_additional_driver_collect > 0){
        //     for (let i = 1; i <= r_additional_driver_collect; i++) {
        //         let voucher_driver = $("#voucher_drivers_" + i);
        //         let driver_import = $("#drivers_" + i + "_import");
        //         let driver_debt = $("#debt_additional_driver_" + i +"_import");
        //         voucher_driver.on('change',function () {
        //             if(!this.checked){
        //
        //             }else{
        //
        //             }
        //         });
        //     }
        // }


        rent_voucher.on('change', function (e) {
            if (!this.checked) {
                rent_import_input.val(myToFixed(parseFloat(rent_import_input.data('initial')), 2));
                debt_rent_import.val(myToFixed(amounts_rent_tariff * days - parseFloat(rent_import_input.data('initial')), 2));
                rent_import_input.attr('readonly', false);
                debt_rent_import.attr('readonly', false);
            } else {
                rent_import_input.val(myToFixed(amounts_rent_tariff * days, 2));
                debt_rent_import.val(myToFixed(0.00, 2));
                rent_import_input.attr('readonly', true);
                debt_rent_import.attr('readonly', true);
            }
            context.updateDebtTotalImport();
            context.updateTotalImport();
        });

        liability_release_voucher.on('change', function () {
            if (!this.checked) {
                liability_release_import_input.val(myToFixed(parseFloat(liability_release_import_input.data('initial')), 2));
                debt_liability_release_import.val(myToFixed(amounts_liability_release_tariff * days - parseFloat(liability_release_import_input.data('initial')), 2));
                liability_release_import_input.attr('readonly', false);
                debt_liability_release_import.attr('readonly', false);
            } else {
                liability_release_import_input.val(myToFixed(amounts_liability_release_tariff * days, 2));
                debt_liability_release_import.val(myToFixed(0.00, 2));
                liability_release_import_input.attr('readonly', true);
                debt_liability_release_import.attr('readonly', true);
            }
            context.updateDebtTotalImport();
            context.updateTotalImport();
        });

        // if(relief_supplement_voucher.checked){
        //     relief_supplement_import_input.val(myToFixed(relief_supplement_tariff * days, 2));
        //     debt_relief_supplement_import.val(myToFixed(0.00, 2));
        //     relief_supplement_import_input.attr('readonly', true);
        //     debt_relief_supplement_import.attr('readonly', true);
        // }else{
        //     relief_supplement_import_input.val(myToFixed(parseFloat(relief_supplement_import_input.data('initial')), 2));
        //     debt_relief_supplement_import.val(myToFixed(relief_supplement_tariff * days - parseFloat(relief_supplement_import_input.data('initial')), 2));
        //     relief_supplement_import_input.attr('readonly', false);
        //     debt_relief_supplement_import.attr('readonly', false);
        // }
        relief_supplement_voucher.on('change', function () {
            if (!this.checked) {
                relief_supplement_import_input.val(myToFixed(parseFloat(relief_supplement_import_input.data('initial')), 2));
                debt_relief_supplement_import.val(myToFixed(relief_supplement_tariff * days - parseFloat(relief_supplement_import_input.data('initial')), 2));
                relief_supplement_import_input.attr('readonly', false);
                debt_relief_supplement_import.attr('readonly', false);
            } else {
                relief_supplement_import_input.val(myToFixed(relief_supplement_tariff * days, 2));
                debt_relief_supplement_import.val(myToFixed(0.00, 2));
                relief_supplement_import_input.attr('readonly', true);
                debt_relief_supplement_import.attr('readonly', true);
            }
            context.updateDebtTotalImport();
            context.updateTotalImport();
        });


        // if (fuel_import_input.attr('readonly') === true) {
            fuel_voucher.on('change', function () {
                if (!this.checked) {
                    relief_supplement_import_input.val(myToFixed(parseFloat(relief_supplement_import_input.data('initial')), 2));
                    fuel_import_input.attr('readonly', false);
                    debt_fuel_import.attr('readonly', false);
                } else {
                    fuel_import_input.attr('readonly', true);
                    debt_fuel_import.attr('readonly', true);
                }
                context.updateDebtTotalImport();
                context.updateTotalImport();
            });
        // }

        // if (office_input.attr('readonly') === true) {

            office_voucher.on('change', function () {
                if (!this.checked) {
                    office_input.attr('readonly', false);
                    debt_office_import.attr('readonly', false);
                } else {
                    office_input.attr('readonly', true);
                    debt_office_import.attr('readonly', true);
                }
                context.updateDebtTotalImport();
                context.updateTotalImport();
            });
        // }

        drop_off_voucher.on('change', function () {
            if (!this.checked) {
                drop_off_input.attr('readonly', false);
                debt_drop_off_import.attr('readonly', false);
            } else {
                drop_off_input.attr('readonly', true);
                debt_drop_off_import.attr('readonly', true);
            }
            context.updateDebtTotalImport();
            context.updateTotalImport();
        });

        other_payments_voucher.on('change', function () {
            if (!this.checked) {
                other_payments_input.attr('readonly', false);
                debt_other_import.attr('readonly', false);
            } else {
                other_payments_input.attr('readonly', true);
                debt_other_import.attr('readonly', true);
            }
            context.updateDebtTotalImport();
            context.updateTotalImport();
        });


    }

    upaymentspdatePaymentAdjustmentDifference(e) {
        let paymentAmountInput = $('#diff_payment_adjustment');
        let initialValue = $('#initial_value').val();
        let spanForDifference = $('#diff_adjustment');

        paymentAmountInput.on('change', function () {
            let inputValue = paymentAmountInput.val();
            if (paymentAmountInput.val() > 0) {
                spanForDifference.text(parseFloat(inputValue) - parseFloat(initialValue));
            } else {
                spanForDifference.text(0.00);
            }
        });

    }

    updateDebtTotalImport(e) {
        //inputs de deudas
        let debt_rent_import = $('input[name="amounts[debt_rent_import]"]').val();
        let debt_liability_release_import = $('input[name="amounts[debt_liability_release_import]"]').val();
        let debt_relief_supplement_import = $('input[name="amounts[debt_relief_supplement_import]"]').val();
        let debt_additional_driver_import = $('input[name="amounts[debt_additional_driver_import]"]').val();
        let debt_fuel_import = $('input[name="amounts[debt_fuel_import]"]').val();
        let debt_office_import = $('input[name="amounts[debt_office_import]"]').val();
        let debt_drop_off_import = $('input[name="amounts[debt_drop_off_import]"]').val();
        let debt_other_import = $('input[name="amounts[debt_other_import]"]').val();

        if (debt_additional_driver_import === undefined || debt_additional_driver_import === null || debt_additional_driver_import === "") {
            debt_additional_driver_import = parseFloat(0);
        }

        let debt_total_import =
            parseFloat(debt_rent_import) +
            parseFloat(debt_liability_release_import) +
            parseFloat(debt_relief_supplement_import) +
            parseFloat(debt_additional_driver_import) +
            parseFloat(debt_office_import) +
            parseFloat(debt_drop_off_import) +
            parseFloat(debt_other_import) +
            parseFloat(debt_fuel_import);
        $('#debt_total_import').text(this.myToFixed(debt_total_import, 2));
        if(this.checkDebtsInitialDiff()){
            $('#btn-adjust').removeClass('disabled');
        }else{
            if(this.has_changes()){
                $('#btn-adjust').removeClass('disabled');
                console.log("activado: updateDebtTotalImport")
            }else{
                $('#btn-adjust').addClass('disabled');
                console.log("desactivado: updateDebtTotalImport");
            }
        }
        this.setMaxValueToDebtsInputs(e);
    }
    checkDebtsInitialDiff(e){
        let flag = false;
        let initial_debt = $("#debt_total_import").data('initial');
        let debt = $("#debt_total_import").text();
        if(parseFloat(initial_debt)!== parseFloat(debt)){
            flag = true;
        }
        return flag;

    }

    resolveDebtTotalImport(e){
        let debt_total_import = 0;
        //inputs de deudas
        let debt_rent_import = $('input[name="amounts[debt_rent_import]"]').val();
        let debt_liability_release_import = $('input[name="amounts[debt_liability_release_import]"]').val();
        let debt_relief_supplement_import = $('input[name="amounts[debt_relief_supplement_import]"]').val();
        let debt_additional_driver_import = $('input[name="amounts[debt_additional_driver_import]"]').val();
        let debt_fuel_import = $('input[name="amounts[debt_fuel_import]"]').val();
        let debt_office_import = $('input[name="amounts[debt_office_import]"]').val();
        let debt_drop_off_import = $('input[name="amounts[debt_drop_off_import]"]').val();
        let debt_other_import = $('input[name="amounts[debt_other_import]"]').val();

        if (debt_additional_driver_import === undefined || debt_additional_driver_import === null || debt_additional_driver_import === "") {
            debt_additional_driver_import = parseFloat(0);
        }
        debt_total_import = parseFloat(debt_rent_import) + parseFloat(debt_liability_release_import) + parseFloat(debt_relief_supplement_import) + parseFloat(debt_additional_driver_import) + parseFloat(debt_office_import) + parseFloat(debt_drop_off_import) + parseFloat(debt_other_import) + parseFloat(debt_fuel_import);
        return debt_total_import;
    }

    paymentAdjustControl(e) {
        let payment_amounts = $('.payments');
        // formas de pago del contrato
        let card = $('input[name="payment_form[card]"]');
        let cash = $('input[name="payment_form[cash]"]');
        let voucher = $('input[name="payment_form[voucher]"]');
        let cheque = $('input[name="payment_form[cheque]"]');
        let other = $('input[name="payment_form[other]"]');
        let moment_card = $('input[name="payment_form[moment_card]"]');
        // forma de pago del cobro del contrato
        let payment_form = $('.payment_form');
        //control de total de importe por forma de pago
        let card_total_input = 0;
        let cash_total_input = 0;
        let voucher_total_input = 0;
        let cheque_total_input = 0;
        let other_total_input = 0;
        let moment_card_total_input = 0;

        if (payment_amounts !== undefined && payment_amounts !== "") {
            let payments = payment_amounts.serializeArray();
            $.each(payments, function (key, payment) {
                if (payment_form[key].textContent === 'Tarjeta al momento') {
                    moment_card_total_input += parseFloat(payment.value);
                }
                if (payment_form[key].textContent === 'Cheque o Transferencia') {
                    cheque_total_input += parseFloat(payment.value);
                }
                if (payment_form[key].textContent === 'Efectivo') {
                    cash_total_input += parseFloat(payment.value);
                }
                if (payment_form[key].textContent === 'Otros pagos') {
                    other_total_input += parseFloat(payment.value);
                }
                if (payment_form[key].textContent === 'Tarjeta anticipado') {
                    card_total_input += parseFloat(payment.value);
                }
                if (payment_form[key].textContent === 'Voucher') {
                    voucher_total_input += parseFloat(payment.value);
                }
            });
            $.each(payments, function (index, item) {
                if (payment_form[index].textContent === 'Tarjeta al momento' && moment_card_total_input > parseFloat(moment_card.val())) {
                    alert(`No puede sobrepasar el monto definido teniendo en cuenta todos los ajustes de cobro en esta sección para la forma de pago correspondiente [${payment_form[index].textContent}]`);
                    $('input[name="' + item.name + '"]').val(myToFixed(parseFloat($('input[name="original_' + item.name + '"]').val()), 2));
                }

                if (payment_form[index].textContent === 'Cheque o Transferencia' && cheque_total_input > parseFloat(cheque.val())) {
                    alert(`No puede sobrepasar el monto definido teniendo en cuenta todos los ajustes de cobro en esta sección para la forma de pago correspondiente [${payment_form[index].textContent}]`);
                    $('input[name="' + item.name + '"]').val(myToFixed(parseFloat($('input[name="original_' + item.name + '"]').val()), 2));
                }

                if (payment_form[index].textContent === 'Efectivo' && cash_total_input > parseFloat(cash.val())) {
                    alert(`No puede sobrepasar el monto definido teniendo en cuenta todos los ajustes de cobro en esta sección para la forma de pago correspondiente [${payment_form[index].textContent}]`);
                    $('input[name="' + item.name + '"]').val(myToFixed(parseFloat($('input[name="original_' + item.name + '"]').val()), 2));
                }

                if (payment_form[index].textContent === 'Otros pagos' && other_total_input > parseFloat(other.val())) {
                    alert(`No puede sobrepasar el monto definido teniendo en cuenta todos los ajustes de cobro en esta sección para la forma de pago correspondiente [${payment_form[index].textContent}]`);
                    $('input[name="' + item.name + '"]').val(myToFixed(parseFloat($('input[name="original_' + item.name + '"]').val()), 2));
                }

                if (payment_form[index].textContent === 'Tarjeta anticipado' && card_total_input > parseFloat(card.val())) {
                    alert(`No puede sobrepasar el monto definido teniendo en cuenta todos los ajustes de cobro en esta sección para la forma de pago correspondiente [${payment_form[index].textContent}]`);
                    $('input[name="' + item.name + '"]').val(myToFixed(parseFloat($('input[name="original_' + item.name + '"]').val()), 2));
                }

                if (payment_form[index].textContent === 'Voucher' && voucher_total_input > parseFloat(voucher.val())) {
                    alert(`No puede sobrepasar el monto definido teniendo en cuenta todos los ajustes de cobro en esta sección para la forma de pago correspondiente [${payment_form[index].textContent}]`);
                    $('input[name="' + item.name + '"]').val(myToFixed(parseFloat($('input[name="original_' + item.name + '"]').val()), 2));
                }
            });
        }
    }

    checkAdjustImportsDifferences(e) {
        let flag = this.isChangeItAnyPaymentImport();
        $("#msg_payments_on_this_page").html("");
        if (flag) {
            $('#btn-adjust').removeClass('disabled');
            console.log("activado: checkAdjustImportsDifferences");
        } else {
            /**
             * $("#msg_payments_on_this_page").html(`<div class='alert alert-warning alert-dismissible fade show' role="alert">
             *                                                 <strong> Alerta: </strong> Debe cuadrar todos los importes de cobros según su forma de pago correspondiente.
             *                                                 <button type="button" class="close" data-dismiss="alert" aria-label="Close">
             *                                                     <span aria-hidden="true">&times;</span>
             *                                                 </button>
             *                                              </div>`);
             */
            $('#btn-adjust').addClass('disabled');
            console.log("descativado: checkAdjustImportsDifferences");
        }
    }

    isChangeItAnyPaymentImport(e){
        let flag = false;
        if ($('.adjust_payments') !== '' && $('.adjust_payments') !== undefined) {
            /**
             * Buscando todos los importes de cobros que tienen una clase que termina con *_payment_form
             * y contienen la palabra amount en el id
             * */
            let adjust_payments_imports = $("input[class$='_payment_form'][id*='amount']");
            let total = 0;
            let total_cash = 0;
            let initial_total_cash = 0;
            let total_moment_card = 0;
            let initial_total_moment_card = 0;
            let total_cheque = 0;
            let initial_total_cheque = 0;
            let total_other = 0;
            let initial_total_other = 0;
            // formas de pago alternativas?
            let total_voucher = parseFloat($('#voucher').val());
            let initial_total_voucher = parseFloat($('#voucher').data('initial'));
            let total_card = parseFloat($('#card').val());
            let initial_total_card =parseFloat($('#card').data('initial'));
            //importes de las formas de pago del contrato
            let cash = $('#cash').val() !== '' ? parseFloat($('#cash').val()) : 0.0;
            let moment_card = $('#moment_card').val() !== '' ? parseFloat($('#moment_card').val()) : 0.0;
            let cheque_transfer = $('#cheque').val() !== '' ? parseFloat($('#cheque').val()) : 0.0;
            let other = $('#other').val() !== '' ? parseFloat($('#other').val()) : 0.0;

            $.each(adjust_payments_imports, function (key, payment) {
                let amount = parseFloat(payment.value);
                let initial_amount = parseFloat(payment.dataset.initial);
                if ($.isNumeric(amount)) {
                    total += amount;
                }
                switch (payment.dataset.code) {
                    case 'TM':
                        if ($.isNumeric(amount)) {
                            total_moment_card += amount;
                            initial_total_moment_card +=initial_amount;
                        }
                        break;
                    case 'EFE':
                        if ($.isNumeric(amount)) {
                            total_cash += amount;
                            initial_total_cash += initial_amount;
                        }
                        break;
                    case 'CHT':
                        if ($.isNumeric(amount)) {
                            total_cheque += amount;
                            initial_total_cheque += initial_amount;
                        }
                        break;
                    case 'OTR':
                        if ($.isNumeric(amount)) {
                            total_other += amount;
                            initial_total_other += initial_amount;
                        }
                        break;
                    default:
                        break;
                }
            });
            //hubo cambios en los importes y todos cuadran (hay ajuste)
            if ((total - (cash + moment_card + cheque_transfer + other) === 0) &&
                (total_cash === cash && total_moment_card === moment_card &&
                    total_cheque === cheque_transfer && total_other === other)
            ) {
                flag = true;
            }
            //cuadran los importes pero aun mantienen los valores iniciales, por lo tantono se debe registrar ajuste a los importes (no hay ajuste)
            if(total - (cash + moment_card + cheque_transfer + other) === 0 && (initial_total_cash === cash && initial_total_moment_card === moment_card && initial_total_cheque === cheque_transfer && initial_total_other === other)){
                flag = false;
            }
            // cuadran los importes de las formas de pago menos la de voucher o la de tarjeta anticipado. (hay ajustes en esas formas de pago)
            if((total - (cash + moment_card + cheque_transfer + other) === 0) && (initial_total_voucher !== total_voucher || initial_total_card !== total_card)){
                flag = true;
            }
            // if (flag) {
            //     $('#btn-adjust').removeClass('disabled');
            // } else {
            //     $('#btn-adjust').addClass('disabled');
            // }
        }

        return flag;
    }

    updateTotalSerImport(e) {
        let context = this;
        // dias
        let amounts_days = $('#amounts_days').val();
        // tarifa por conceptos
        let amounts_rent_tariff = $('#amounts_rent_tariff').val();
        let amounts_liability_release_tariff = $('#amounts_liability_release_tariff').val();
        let relief_supplement_tariff = $('#relief_supplement_tariff').val();
        let amounts_fuel_tariff = $('#amounts_fuel_tariff').val();
        // importes
        let amounts_fuel_import = $('#amounts_fuel_import').val();
        let amounts_office = $('#amounts_office').val();
        let amounts_drop_off = $('#amounts_drop_off').val();
        let amounts_other_payments = $('#amounts_other_payments').val();
        //deudas
        let debt_rent_import = $('#debt_rent_import').val();
        if(debt_rent_import === '' || debt_rent_import === undefined || debt_rent_import === null) //chequeando que el valor no se undefina y no se pueda hacer la sumatoria
            debt_rent_import.val(0.00);
        let debt_liability_release_import = $('#debt_liability_release_import').val();
        if(debt_liability_release_import === '' || debt_liability_release_import === undefined || debt_liability_release_import === null) //chequeando que el valor no se undefina y no se pueda hacer la sumatoria
            debt_liability_release_import.val(0.00);
        let debt_relief_supplement_import = $('#debt_relief_supplement_import').val();
        if(debt_relief_supplement_import === '' || debt_relief_supplement_import === undefined || debt_relief_supplement_import === null) //chequeando que el valor no se undefina y no se pueda hacer la sumatoria
            debt_relief_supplement_import.val(0.00);
        let debt_fuel_import = $('#debt_fuel_import').val();
        if(debt_fuel_import === '' || debt_fuel_import === undefined || debt_fuel_import === null) //chequeando que el valor no se undefina y no se pueda hacer la sumatoria
            debt_fuel_import.val(0.00);
        let debt_office_import = $('#debt_office_import').val();
        if(debt_office_import === '' || debt_office_import === undefined || debt_office_import === null) //chequeando que el valor no se undefina y no se pueda hacer la sumatoria
            debt_office_import.val(0.00);
        let debt_drop_off_import = $('#debt_drop_off_import').val();
        if(debt_drop_off_import === '' || debt_drop_off_import === undefined || debt_drop_off_import === null) //chequeando que el valor no se undefina y no se pueda hacer la sumatoria
            debt_drop_off_import.val(0.00);
        let debt_other_import = $('#debt_other_import').val();
        if(debt_other_import === '' || debt_other_import === undefined || debt_other_import === null) //chequeando que el valor no se undefina y no se pueda hacer la sumatoria
            debt_other_import.val(0.00);
        // spans de calculo de importe total del ser
        let rent_total_ser = $('#rent_ser_total');
        let liability_release_ser_total = $('#liability_release_ser_total');
        let relief_supplement_ser_total = $('#relief_supplement_ser_total');
        let fuel_ser_total = $('#fuel_ser_total');
        let office_ser_total = $('#office_ser_total');
        let drop_off_ser_total = $('#drop_off_ser_total');
        let payment_ser_total = $('#payment_ser_total');
        // let additional_driver_ser_total = $('#additional_driver_ser_total');
        // total de totales del SER
        let total_ser_import = $('#total_ser_import');
        //seccion choferes adicionales
        let additional_driver_tariff = $('#additional_driver_tariff').val();
        let r_additional_driver_collect = $("#r_additional_driver_collect").val(); // cantidad de choferes cobrados en la renta
        let count_drivers_import = 0
        if(r_additional_driver_collect > 0){
            for (let i = 1; i <= r_additional_driver_collect; i++) {
                let r_driver_import = amounts_days * additional_driver_tariff;
                $("#additional_driver_" + i + "_ser_total").text(this.myToFixed(r_driver_import, 2));
                count_drivers_import += parseFloat(r_driver_import);
            }
        }

        rent_total_ser.text(myToFixed((parseInt(amounts_days) * parseFloat(amounts_rent_tariff) + parseFloat(debt_rent_import)) - parseFloat(debt_rent_import), 2));
        liability_release_ser_total.text(myToFixed((parseInt(amounts_days) * parseFloat(amounts_liability_release_tariff) + parseFloat(debt_liability_release_import)) - parseFloat(debt_liability_release_import), 2));
        relief_supplement_ser_total.text(myToFixed((parseInt(amounts_days) * parseFloat(relief_supplement_tariff) + parseFloat(debt_relief_supplement_import)) - parseFloat(debt_relief_supplement_import), 2));
        fuel_ser_total.text(myToFixed((parseFloat(amounts_fuel_import) + parseFloat(debt_fuel_import)), 2));
        office_ser_total.text(myToFixed((parseFloat(amounts_office) + parseFloat(debt_office_import)), 2));
        drop_off_ser_total.text(myToFixed((parseFloat(amounts_drop_off) + parseFloat(debt_drop_off_import)), 2));
        payment_ser_total.text(myToFixed((parseFloat(amounts_other_payments) + parseFloat(debt_other_import)), 2));

        total_ser_import.text(myToFixed(
            parseFloat(rent_total_ser.text()) +
            parseFloat(liability_release_ser_total.text()) +
            parseFloat(relief_supplement_ser_total.text()) +
            parseFloat(fuel_ser_total.text()) +
            parseFloat(office_ser_total.text()) +
            parseFloat(drop_off_ser_total.text()) +
            parseFloat(payment_ser_total.text()) + count_drivers_import, 2));
    }

    // unCheckNoVouchersOperations(e){
    //     let vouchers = $('.check-change');
    //     let voucher_flag = this.data.get("voucher");
    //     if(voucher_flag === 'false'){
    //         $.each(vouchers, function (index, voucher) {
    //             $("#" + voucher.id).prop('disabled', 'true');
    //         });
    //     }
    // }

    checkChangesInAdjusmentsFields(e){
        let adjustments_fields = $(".adjustment_field");
        let flag = false;
        for (let i =0; i <adjustments_fields.length -1; i++){
            let current = adjustments_fields[i].value;
            let initial = adjustments_fields[i].dataset.initial;
                if(current!==initial){
                    flag = true;
                }
        }
        if(flag){
            $('#btn-adjust').removeClass('disabled');
            console.log("activado: checkChangesInAdjusmentsFields");
        }else{
            $('#btn-adjust').addClass('disabled');
            console.log("desactivado: checkChangesInAdjusmentsFields");
        }
    }

    checkChangesInDebts(debt){
        let flag = false;
        if((parseFloat(debt.value) !== parseFloat(debt.dataset.initial)) && parseFloat(debt.value) > 0){
            flag = true;
            return flag;
        }
        return flag;
    }

    setMaxValueToDebtsInputs(e){
        let context = this;
        let days = parseInt($("#amounts_days").val());
        //inputs de deudas
        let debt_rent_import = $('input[name="amounts[debt_rent_import]"]');
        let debt_liability_release_import = $('input[name="amounts[debt_liability_release_import]"]');
        let debt_relief_supplement_import = $('input[name="amounts[debt_relief_supplement_import]"]');
        let debt_additional_driver_import = $('input[name="amounts[debt_additional_driver_import]"]');
        let debt_fuel_import = $('input[name="amounts[debt_fuel_import]"]');
        //inputs de tarifas
        let amounts_rent_tariff = parseFloat($('#amounts_rent_tariff').val());
        let amounts_liability_release_tariff = parseFloat($('#amounts_liability_release_tariff').val());
        let relief_supplement_tariff = parseFloat($('#relief_supplement_tariff').val());
        let amounts_fuel_tariff = parseFloat($('#amounts_fuel_tariff').val());
        let additional_driver_tariff = parseFloat($('#additional_driver_tariff').val());
        // inputs de importes
        let rent_import_input = $('#amounts_rent_import');
        let liability_release_import_input = $('#liability_release_import');
        let relief_supplement_import_input = $('#relief_supplement_import');
        let drivers_input = $('.drivers');
        let fuel_import_input = $('#amounts_fuel_import');
        // choferes
        let r_additional_driver_collect = $("#r_additional_driver_collect").val(); // cantidad de choferes cobrados en la renta

        debt_rent_import.attr('max', (amounts_rent_tariff * days) - parseFloat(rent_import_input.val()));
        if((amounts_rent_tariff * days) - parseFloat(rent_import_input.val()) < parseFloat(debt_rent_import.val())){
            debt_rent_import.val(debt_rent_import.slice(0,(amounts_rent_tariff * days) - parseFloat(rent_import_input.val())));
        }
        debt_liability_release_import.attr('max', (amounts_liability_release_tariff * days) - parseFloat(liability_release_import_input.val()));
        if((amounts_liability_release_tariff * days) - parseFloat(liability_release_import_input.val()) < parseFloat(debt_liability_release_import.val())){
            debt_liability_release_import.val(debt_liability_release_import.slice(0,(amounts_liability_release_tariff * days) - parseFloat(liability_release_import_input.val())));
        }
        debt_relief_supplement_import.attr('max', (relief_supplement_tariff * days) - parseFloat(relief_supplement_import_input.val()));
        if((relief_supplement_tariff * days) - parseFloat(relief_supplement_import_input.val()) < parseFloat(debt_relief_supplement_import.val())){
            debt_relief_supplement_import.val(debt_relief_supplement_import.slice(0,(relief_supplement_tariff * days) - parseFloat(relief_supplement_import_input.val())));
        }
        if(r_additional_driver_collect > 0){
            for (let i = 1; i <= r_additional_driver_collect; i++) {
                let driver_debt = $("#debt_additional_driver_" + i +"_import");
                let driver_import = $("#drivers_" + i + "_import");
                driver_debt.attr('max', (additional_driver_tariff * days) - parseFloat(driver_import.val()));
                if((additional_driver_tariff * days) - parseFloat(driver_import.val()) < parseFloat(driver_debt.val())){
                    driver_debt.val(driver_debt.slice(0,(additional_driver_tariff * days) - parseFloat(driver_import.val())));
                }
            }
        }
        // debt_fuel_import.attr('max', (amounts_fuel_tariff * days) - parseFloat(fuel_import_input.val()));
        // if((amounts_fuel_tariff * days) - parseFloat(fuel_import_input.val()) < parseFloat(debt_fuel_import.val())){
        //     debt_fuel_import.val(debt_fuel_import.slice(0,(amounts_fuel_tariff * days) - parseFloat(fuel_import_input.val())));
        // }
    }

    checkFuelImport(e){
        let flag = false;
        let amounts_fuel_import = $("#amounts_fuel_import");
        let amounts_fuel_inicial_import = $("#amounts_fuel_import").data("initial");

        if(parseFloat(amounts_fuel_import.val()) !== parseFloat(amounts_fuel_inicial_import)){
            flag = true;
        }
        return flag;
    }

}
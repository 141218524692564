import {Controller} from "stimulus";
import $ from "jquery";

// require("timepicker/jquery.timepicker.css")
// require("timepicker/jquery.timepicker.js")
// require("select2/dist/css/select2");
// require("select2-bootstrap-theme/dist/select2-bootstrap");

export default class extends Controller {
    connect() {
        $("#parking_business").on('select2:select', function () {
            let event = new Event('change', {bubbles: true}) // fire a native event
            this.dispatchEvent(event);
        });
    }

    toggleFailureTypes(e){
        let container = this.data.get("container");
        if($('.parking-available').is(":checked") === true) {
            $("#div_not_available_causes").show();
        }else{
            $("#div_not_available_causes").hide();
        }
    }

    toggleOperativeFailureTypes(e){
        if($('.parking-operative').is(":checked") === true) {
            $("#div_not_operative_causes").show();
        }else{
            $("#div_not_operative_causes").hide();
        }
    }

    //Obtener los puntos de renta por sucursal
    getHirePointsByBusinesses(e){
        let url = this.data.get("business_url");
        jQuery.ajax({
            async: true,
            evalScripts: true,
            type: "POST",
            contentType: "application/x-www-form-urlencoded",
            url: url,
            data: {id: e.target.value},
            success: function (res) {
                $("#parking_hire_point_id").empty();
                $.each(res.hire_points, function (index, hire_point) {
                    $("#parking_hire_point_id").append(
                        $("<option>")
                            .text(hire_point.name)
                            .attr("value", hire_point.id)
                    );
                });
            },
            error: function (jqXHR, textStatus, errorThrown) {
                if (jqXHR.getResponseHeader('REQUIRES_AUTH') === '1') {
                    alert('Su session expiró, por favor acceda nuevamente');
                    window.location = jqXHR.getResponseHeader('ROOT_URL');
                }else{
                    let message = "";
                    if (jqXHR.status === 0) {
                        message = 'Sin conexión: Verifique su conexión de red.';
                    } else if(jqXHR.status == 400){
                        message = ' Existen problemas en el request';
                    } else if(jqXHR.status == 401){
                        message = 'Autentiquece nuevamente.';
                    }else if (jqXHR.status == 403) {
                        message = "Autentiquece nuevamente.";
                    } else if (jqXHR.status == 404) {
                        message = 'Página no encontrada [404]';
                    } else if (jqXHR.status == 500) {
                        message = 'Ha ocurrido un error [500] que fue notificado al equipo de soporte, intene nuevamente en unos minutos.';
                    } else if (jqXHR.status == 503){
                        message = "Service unavailable.";
                    } else if (textStatus === 'parsererror') {
                        message = 'Ha ocurrido un error de interno [JSON] que fue notificado al equipo de soporte, intene nuevamente en unos minutos';
                    } else if (textStatus === 'timeout') {
                        message = 'Time out, tiempo de espera superado, inicie nuevmente, verifique su conexión de red.';
                    } else if (textStatus === 'abort') {
                        message = 'Request abortado.';
                    } else {
                        message = 'Error desconocido: ' + jqXHR.responseText;
                    }
                    // responseText
                    // $.ajax({
                    //     async: true,
                    //     evalScripts:true,
                    //     type: "POST",
                    //     url: "/mail_error?errorStatus="+ jqXHR.status +"&clientMessage=" + message + "&errorThrown=" + errorThrown + "&url=" + this.url,
                    //     data: {error: jqXHR.responseText}
                    // });
                    alert(message);
                }
            },
        });
    }
}
